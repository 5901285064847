class Checks {
    private regex =
        /J|K|W|X|Y|TH|HI|KH|SH|PH|H|ZH|EH|VV|EE|AA|OO|YY|VY|VW|KV|GJ|QW|XJ|ZV|HH|QY|WJ|GZ|FJ|KJ|QZ|VX|WX|ZX|JX|VJ|FZ|QX|KZ|VZ|JZ|QJ|QK|ZJ|QV|QW|QX|QY|QZ|TH|PH|SH|CK|XZ|VV|WZ|JH|QW|GZ|GJ|GY|VY|VJ|FJ|DZ|SZ|KJ|KZ|WJ|WX|ZX|ZJ|ZK|ZV/gm;
    private regexFirstName = /J|H/gm;
    private names = [
        "GIUSJ",
        "JACOPO",
        "JESSICA",
        "JENNIFER",
        "JOSE",
        "JASMINE",
        "JOLANDA",
        "JONATHAN",
        "SARAH",
        "THOMAS",
        "DEBORAH",
        "KATHIA",
        "SHEILA",
        "SHARON",
        "SAMANTHA",
    ];
    private groupLetters = /GH|CH/gm;
    private hCheck = /H/gm;
    private containsNumbers = (str: string) => /\d/.test(str);
    public isValidLength = (str: string) =>
        str.length >= 4 && str.length <= 20;
    public hasValidWordCount = (str: string) =>
        str.trim().split(" ").length <= 3;
    private hasElInMultiWord = (str: string) =>
        str.split(" ").length > 1 && str.includes(" EL ");
    private hasInvalidAccent = (str: string) =>
        /[\u00C0-\u00C5\u00C8-\u00CB\u00CC-\u00CF\u00D2-\u00D6\u00D9-\u00DC\u00E0-\u00E5\u00E8-\u00EB\u00EC-\u00EF\u00F2-\u00F6\u00F9-\u00FC]/.test(
            str
        ) &&
        !/[\u00C0-\u00C5\u00C8-\u00CB\u00CC-\u00CF\u00D2-\u00D6\u00D9-\u00DC\u00E0-\u00E5\u00E8-\u00EB\u00EC-\u00EF\u00F2-\u00F6\u00F9-\u00FC]$/.test(
            str
        );
    private hasDieresis = (str: string) => /[ÄËÏÖÜäëïöü]/.test(str);
    private hasInvalidXyw = (str: string) => /[XYW]/.test(str.slice(1, -1));
    private hasShortWordsInMultiWord = (str: string) => {
        const words = str.split(" ");
        return words.length > 2 && words.every((word) => word.length <= 3);
    };
    private isValidGeneral = (str: string) =>
        this.isValidLength(str) &&
        this.hasValidWordCount(str) &&
        !this.hasElInMultiWord(str) &&
        !this.hasInvalidAccent(str) &&
        !this.hasDieresis(str) &&
        !this.hasInvalidXyw(str) &&
        !this.containsNumbers(str) &&
        !this.hasShortWordsInMultiWord(str);

    /**
     *
     */
    constructor() {}

    checkPhone(phone: string) {
        var regex = /^[0-9]+$/;
        if (!regex.test(phone)) {
            return false;
        }
        if (
            phone == "" ||
            phone.length < 9 ||
            phone.length > 10 ||
            phone.indexOf("333333") !== -1 ||
            phone.indexOf("111111") !== -1 ||
            phone.indexOf("000000") !== -1 ||
            phone.indexOf("222222") !== -1 ||
            phone.indexOf("444444") !== -1 ||
            phone.indexOf("555555") !== -1 ||
            phone.indexOf("666666") !== -1 ||
            phone.indexOf("777777") !== -1 ||
            phone.indexOf("888888") !== -1 ||
            phone.indexOf("999999") !== -1
        ) {
            return false;
        }
        var prefix = [
            "320",
            "323",
            "324",
            "327",
            "328",
            "329",
            "330",
            "331",
            "333",
            "334",
            "335",
            "336",
            "337",
            "338",
            "339",
            "340",
            "341",
            "342",
            "343",
            "344",
            "345",
            "346",
            "347",
            "348",
            "349",
            "350",
            "351",
            "360",
            "362",
            "363",
            "366",
            "368",
            "370",
            "371",
            "373",
            "375",
            "379",
            "353",
            "377",
            "380",
            "383",
            "385",
            "388",
            "389",
            "390",
            "391",
            "392",
            "393",
            "397",
        ];
        var prefix_tel = phone.substring(0, 3);

        return prefix.indexOf(prefix_tel) > -1;
    }

    checkEmail(email: string) {
        var filter =
            /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        return String(email).search(filter) != -1;
    }

    dateIsValid(dateStr: string) {
        debugger;
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    
        if (dateStr.match(regex) === null) {
            return false;
        }
    
        const [day, month, year] = dateStr.split('/');
    
        // 👇️ format Date string as `yyyy-mm-dd`
        const isoFormattedStr = `${year}-${month}-${day}`;
    
        const date = new Date(isoFormattedStr);
    
        const timestamp = date.getTime();
    
        if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
            return false;
        }
    
        return date.toISOString().startsWith(isoFormattedStr);
    }

    checkNames(first_name: string, last_name: string) {
        first_name = first_name.trim().toUpperCase();
        last_name = last_name.trim().toUpperCase();

        let firstNameIsValid = false;
        let lastNameIsValid = false;

        if (first_name) {
            // console.log(first_name);
            if (this.names.includes(first_name)) {
                firstNameIsValid = true;
                // console.log('names');
            } else if (first_name.match(this.groupLetters)) {
                firstNameIsValid = true;
                // console.log('groupLetters');
            } else if (
                first_name.match(this.regexFirstName) &&
                this.names.includes(first_name)
            ) {
                firstNameIsValid = true;
                // console.log('regexFirstName && names');
            } else if (
                first_name.indexOf("J") > -1 ||
                first_name.indexOf("H") > -1
            ) {
                firstNameIsValid = false;
                // console.log('J || H');
            } else if (!this.isValidGeneral(first_name)) {
                firstNameIsValid = false;
            } else if (
                first_name[first_name.length - 1] === "S" ||
                first_name[first_name.length - 1] === "F" ||
                first_name[first_name.length - 1] === "P" ||
                first_name[first_name.length - 1] === "C"
            ) {
                lastNameIsValid = false;
            } else {
                // console.log('true default');
                firstNameIsValid = true;
            }
        } else {
            if (first_name.length > 0) {
                firstNameIsValid = true;
            } else {
                firstNameIsValid = false;
            }
        }

        if (last_name) {
            if (this.names.includes(last_name)) {
                lastNameIsValid = true;
            } else if (last_name.match(this.groupLetters)) {
                lastNameIsValid = true;
            } else if (
                last_name.match(this.hCheck) &&
                !last_name.match(this.groupLetters)
            ) {
                lastNameIsValid = false;
            } else if (last_name.match(this.regex)) {
                lastNameIsValid = false;
            } else if (!this.isValidGeneral(last_name)) {
                lastNameIsValid = false;
            } else if (
                last_name[last_name.length - 1] === "S" ||
                last_name[last_name.length - 1] === "F" ||
                last_name[last_name.length - 1] === "P" ||
                last_name[last_name.length - 1] === "C"
            ) {
                lastNameIsValid = false;
            } else {
                lastNameIsValid = true;
            }
        } else {
            if (first_name.length > 0) {
                lastNameIsValid = true;
            } else {
                lastNameIsValid = false;
            }
        }

        return lastNameIsValid && firstNameIsValid;
    }
}

export default Checks;
