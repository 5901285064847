<template>
    <div class="card flex justify-content-center test">
        <AutoComplete @item-select="handler()" forceSelection placeholder="Scrivi qui..." v-model="city" :suggestions="items" @complete="search" />
    </div>
</template>

<script lang="ts">
import { dataCities } from "/src/assets/cities.js";
import AutoComplete from "primevue/autocomplete";

export default {
    components: {
        AutoComplete,
    },
    name: "AutocompleteCities",
    data() {
        return {
            city: '',
            items: []
        };
    },
    methods: {
        filterData(searchText) {
            if (!searchText) return [];
            return dataCities.filter((item) =>
                item.toLowerCase().includes(searchText.toLowerCase())
            );
        },
        search(event) {
            const filteredSuggestions = this.filterData(event.query);
            this.items = filteredSuggestions.slice(0, 10);
        },
        handler() {
            this.$emit("selectedCityEvent", this.city)
        },
    },
};
</script>

<style>
/* .p-component {
    padding: inherit;
}

.p-autocomplete {
    border: none !important;
} */
/* .p-autocomplete {
    border: none !important;
    color: rgb(47, 50, 74);
    font-size: 16px;
    height: auto;
    box-shadow: none;
    width: 100%;
    max-width: 280px;
    border-color: rgba(74, 74, 74, 1);
    padding: 10px;
    border: 2px solid rgba(74, 74, 74, 1);
    border-radius: 5px;
} */
.test {
    border: none !important;
    color: rgb(47, 50, 74);
    font-size: 16px;
    height: auto;
    box-shadow: none;
    width: 100%;
    max-width: 280px;
    border-color: rgba(74, 74, 74, 1);
    padding: 10px;
    border: 2px solid rgba(74, 74, 74, 1);
    border-radius: 5px;
}
</style>