import { createRouter, createWebHistory } from "vue-router";
import Main from "@/components/Main.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/corso-istituto-cortivo/",
            component: Main
        },
        {
            path: "/corso-istituto-cortivo/thankyou",
            name: 'thank-you',
            component: () => import("../pages/Thankyou.vue"),
        },
    ],
});

export default router;
