<template>
    <main>
        <div
            class="container_"
            ref="scrollContainer"
            style="min-height: 80vh; height: 100%"
        >
            <div id="chat" class="chat mt-[100px] mx-auto">
                <div id="messages" class="messages max-w[600px] w-full mx-auto">
                    <div
                        v-for="(messagge, index) in messagges.slice(0, step)"
                        :key="index"
                    >
                        <div
                            id="bot"
                            class="bot response showUP classNumNaN"
                            style="background-color: white"
                        >
                            <img
                                v-if="messagge.isAvatar"
                                src="https://static.landbot.io/daisho/img/avatar-landbot-12.png"
                            />
                            <span v-if="messagge.isAvatar" class="time">{{
                                messagge.time
                            }}</span>
                            <span v-if="messagge.await">
                                <div id="spinnerTwo" class="spinner">
                                    <div class="bounce1"></div>
                                    <div class="bounce2"></div>
                                    <div class="bounce3"></div>
                                </div>
                            </span>
                            <div v-else>
                                <span v-html="messagge.question"> </span>
                                <button
                                    v-if="messagge.confirmBtn"
                                    @click="
                                        this.handleConfirmation(
                                            messagge.questionName
                                        )
                                    "
                                    class="btn clearBtn"
                                    ref="confirmBtn"
                                    aria-label=""
                                >
                                    <span class="input-button-label"
                                        >CONFERMA</span
                                    >
                                </button>
                                <div
                                    v-if="messagge.isMultiplyBtns"
                                    class="mt-[20px]"
                                >
                                    <button
                                        v-for="(
                                            button, index
                                        ) in messagge.multiplyBtns"
                                        :key="index"
                                        class="btn clearBtn"
                                        @click="
                                            handleConfirmation(
                                                messagge.questionName,
                                                button.value
                                            )
                                        "
                                    >
                                        <span class="input-button-label"
                                            >{{ button.name }}
                                        </span>
                                    </button>
                                </div>
                                <div v-if="messagge.isInput">
                                    <div
                                        style="
                                            background: #004b80;
                                            border-radius: 12px;
                                            text-align: center;
                                        "
                                    >
                                        <div class="column is-full">
                                            <label
                                                class="label"
                                                style="color: #fff"
                                                ><strong>CITTÀ</strong></label
                                            >
                                            <AutocompleteCities
                                                @selectedCityEvent="
                                                    handleSelectedCity($event)
                                                "
                                            ></AutocompleteCities>

                                            <button
                                                class="clearBtn"
                                                style="
                                                    margin-bottom: 10px;
                                                    background: #fff;
                                                    padding: 10px;
                                                    border: 1px solid
                                                        transparent;
                                                    border-radius: 5px;
                                                "
                                                @click="clickProvinceCheck()"
                                            >
                                                CONFERMA
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="messagge.isForm">
                                    <form action="POST" name="form">
                                        <div class="column is-full">
                                            <label class="label">
                                                <strong> NOME</strong></label
                                            >
                                            <input
                                                class="input js-auto-focus"
                                                name="first_name"
                                                v-model="formData.first_name"
                                                ref="first_name"
                                                id="first_name"
                                                placeholder="Scrivi qui..."
                                                type="text"
                                            />
                                        </div>
                                        <div class="column is-full">
                                            <label class="label">
                                                <strong>COGNOME</strong></label
                                            >
                                            <input
                                                class="input js-auto-focus"
                                                name="last_name"
                                                v-model="formData.last_name"
                                                ref="last_name"
                                                id="last_name"
                                                placeholder="Scrivi qui..."
                                                type="text"
                                            />
                                        </div>
                                        <div class="column is-full">
                                            <label class="label">
                                                <strong> Email</strong></label
                                            >
                                            <input
                                                class="input js-auto-focus"
                                                name="email"
                                                v-model="formData.email"
                                                ref="email"
                                                id="email"
                                                placeholder="Scrivi qui..."
                                                type="text"
                                            />
                                        </div>
                                        <div class="column is-full">
                                            <label class="label">
                                                <strong>
                                                    Telefono</strong
                                                ></label
                                            >
                                            <input
                                                class="input js-auto-focus"
                                                name="mobile_phone"
                                                v-model="formData.mobile_phone"
                                                ref="mobile_phone"
                                                id="mobile_phone"
                                                placeholder="Scrivi qui..."
                                                type="text"
                                            />
                                        </div>
                                        <div class="column is-full">
                                            <label class="label">
                                                <strong>
                                                    Data di nascita</strong
                                                ></label
                                            >
                                            <!-- <input class="input js-auto-focus hasDatepicker" v-model="formData.birth_date" ref="birth_date" name="birth_date" id="birth_date" placeholder="Scrivi qui..." type="text"> -->
                                            <VueDatePicker
                                                class="input js-auto-focus hasDatepicker"
                                                style="padding: 5px"
                                                v-model="date"
                                                placeholder="Scrivi qui..."
                                                text-input
                                                :enable-time-picker="false"
                                                :format="format"
                                                :minDate="minDate"
                                                :maxDate="maxDate"
                                                auto-apply
                                            />
                                        </div>
                                        <div class="error" id="error">
                                            {{ errors[0] }}
                                        </div>
                                        <div class="control">
                                            <button
                                                class="sendBtn clearBtn"
                                                @click="validateForm()"
                                                type="button"
                                            >
                                                RICHIEDI INFORMAZIONI
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div v-if="messagge.isInvalidPhone">
                                    <div class="column is-full">
                                        <label class="label">
                                            <strong> Telefono</strong></label
                                        >
                                        <input
                                            class="input js-auto-focus"
                                            name="invalid_phone"
                                            v-model="invalidPhone"
                                            ref="invalid_phone"
                                            id="invalid_phone"
                                            placeholder="Scrivi qui..."
                                            type="text"
                                        />
                                    </div>
                                    <div
                                        class="control"
                                        style="text-align: center"
                                    >
                                        <button
                                            class="sendBtn clearBtn"
                                            @click="checkInvalidPhone()"
                                            type="button"
                                        >
                                            CONTINUA
                                        </button>
                                    </div>
                                    <div
                                        class="error"
                                        style="
                                            margin-top: 10px;
                                            text-align: center;
                                        "
                                    >
                                        {{ invalidPhoneError }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="user"
                            class="user response"
                            :class="{ empty: !messagge.answer }"
                        >
                            <div v-html="messagge.answer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ref="bottomEl" style="height: 1px"></div>
    </main>
</template>

<script lang="ts">
import { ref } from "vue";
import axios from "axios";
import moment from "moment";
import questions from "../questions/questions";
import EventsTracking from "@/shared/eventsTracking";
import AutocompleteCities from "@/components/AutocompleteCities.vue";
import Checks from "../shared/checks";
import Utils from "@/shared/utils";

export default {
    components: {
        AutocompleteCities,
    },
    setup() {
        const date = ref(new Date());
        const format = (date) => {
            const day =
                date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month =
                date.getMonth() + 1 < 10
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        };
        // Calculate min and max dates for 100 years ago and today
        const maxDate = new Date();
        const minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 100);
        return {
            format,
            date,
            maxDate,
            minDate,
        };
    },
    data() {
        const eventTracking: EventsTracking = new EventsTracking(
            import.meta.env.VITE_APP_NAME_LANDING,
            import.meta.env.VITE_APP_UUID_CUSTOMER,
            import.meta.env.VITE_APP_UUID_LANDING
        );

        return {
            step: 1,
            messagges: questions,
            formData: {
                citta: "",
                first_name: "",
                last_name: "",
                email: "",
                mobile_phone: "",
                birth_date: "",
            },
            invalidPhone: "",
            invalidPhoneError: "",
            errors: [] as string[],
            nameLanding: "istitutocortivo_chat_test",
            esperienza_scolastica: "",
            internetAccess: "",
            user_type: "",
            uuid_landing: "30f8840c-597b-40b2-b2b8-141932ee1acf",
            uuid_customer: "3c1aeb04-1af3-437e-934c-1be7d5260119",
            eventTracking,
            selectedCity: "",
        };
    },
    methods: {
        handleSelectedCity(e: string) {
            this.selectedCity = e;
        },
        checkInvalidPhone() {
            if (!this.invalidPhone) {
                this.invalidPhoneError = "Inserisci un numero esatto";
            } else {
                this.sendLead(true);
            }
        },
        getCurrentTimeWithPadding() {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, "0");
            const minutes = String(now.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
        },
        clearButtons() {
            var buttons = document.querySelectorAll(".clearBtn");
            buttons.forEach((el) => {
                (el as HTMLElement).style.display = "none";
            });
        },
        nextStep() {
            setTimeout(() => {
                this.scrollToBottom();
                this.clearButtons();
            }, 1);
            if (this.step < this.messagges.length) {
                this.step++;
                this.messagges[this.step - 1].time =
                    this.getCurrentTimeWithPadding();
                setTimeout(() => {
                    this.messagges[this.step - 1].await = false;
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 1);

                    if (
                        this.messagges[this.step - 1].questionName === "citta"
                    ) {
                        setTimeout(() => {
                            //this.initMap();
                        }, 1000);
                    }
                    if (
                        this.messagges[this.step - 1].questionName === "privacy"
                    ) {
                        setTimeout(() => {
                            document.getElementById("nomeHtml").innerHTML =
                                this.formData.first_name +
                                " " +
                                this.formData.last_name;
                        }, 500);
                    }

                    if (this.messagges[this.step - 1].skip) {
                        this.nextStep();
                    }
                }, 2000);
            }
        },
        handleConfirmation(name: any, value: any) {
            if (name === "esperienza_scolastica" || name === "user_type") {
                const selectedValue = document.querySelectorAll(
                    `input[name="${name}"]:checked`
                );
                if (selectedValue.length > 0) {
                    const selectedOptions = Array.from(selectedValue).map(
                        (checkbox) => checkbox.value
                    );
                    this.messagges[this.step - 1].answer =
                        selectedOptions.join("<br>");
                    this[name] = selectedOptions.join(",");

                    if (name === "esperienza_scolastica") {
                        this.eventTracking.trackEvent(
                            this.nameLanding + "_school",
                            "track",
                            selectedOptions.join(","),
                            true,
                            false,
                            null,
                            null
                        );

                        if (this.$gtm.enabled()) {
                            window.dataLayer?.push({
                                event: this.nameLanding + "_school",
                                event_value: selectedOptions.join(","),
                            });
                        }
                    }

                    if (name === "user_type") {
                        this.eventTracking.trackEvent(
                            this.nameLanding + "_employment",
                            "click",
                            selectedOptions.join(","),
                            true,
                            false,
                            null,
                            null
                        );

                        if (this.$gtm.enabled()) {
                            window.dataLayer?.push({
                                event: this.nameLanding + "_employment",
                                event_value: selectedOptions.join(","),
                            });
                        }
                    }

                    this.nextStep();
                    // Do something with the selected values
                } else {
                    alert("Si prega scegliere un'opzione");
                }
            } else {
                this.messagges[this.step - 1].answer = value;
                this[name] = value;
                if (name === "internetAccess") {
                    this.eventTracking.trackEvent(
                        this.nameLanding + "_internet",
                        "click",
                        value,
                        true,
                        false,
                        null,
                        null
                    );

                    if (this.$gtm.enabled()) {
                        window.dataLayer?.push({
                            event: this.nameLanding + "_internet",
                            event_value: value,
                        });
                    }
                }
                if (value === "ACCETTO") {
                    this.sendLead();

                    this.eventTracking.trackEvent(
                        this.nameLanding + "_privacy",
                        "click",
                        value,
                        true,
                        false,
                        null,
                        null
                    );

                    if (this.$gtm.enabled()) {
                        window.dataLayer?.push({
                            event: this.nameLanding + "_privacy",
                            event_value: value,
                        });
                    }
                }
                if (value === "RIFIUTO") {
                    this.messagges.push({
                        id: this.messagges.length,
                        question:
                            "Ti contatteremo solo per darti più informazioni sul corso di Istituto Cortivo e la borsa di studio, non useremo i tuoi dati per altri scopi. Accetta l'informativa privacy per proseguire senza impegno.",
                        answer: "",
                        confirmBtn: false,
                        isMultiplyBtns: true,
                        multiplyBtns: [
                            {
                                id: 1,
                                name: "ACCETTO ✅",
                                value: "ACCETTO",
                            },
                            {
                                id: 2,
                                name: "RIFIUTO 🚫",
                                value: "RIFIUTO",
                            },
                        ],
                        await: true,
                        questionName: "privacy",
                        isAvatar: true,
                        time: "",
                    });

                    this.eventTracking.trackEvent(
                        this.nameLanding + "_privacy",
                        "click",
                        value,
                        true,
                        false,
                        null,
                        null
                    );

                    if (this.$gtm.enabled()) {
                        window.dataLayer?.push({
                            event: this.nameLanding + "_privacy",
                            event_value: value,
                        });
                    }
                }
                this.nextStep();
            }
        },
        pushInvalidPhone() {
            this.messagges.push({
                id: this.messagges.length,
                question:
                    "Ops! Sembra che il numero di telefono che hai inserito non sia corretto, inserisci un numero valido per continuare",
                answer: "",
                confirmBtn: false,
                await: true,
                time: "",
                isInvalidPhone: true,
            });
        },
        pushAnswersValid() {
            this.messagges.push(
                {
                    id: this.messagges.length,
                    question:
                        "Ottimo, ti contatteremo a brevissimo su WhatsApp, tieni il telefono vicino!",
                    answer: "",
                    confirmBtn: false,
                    await: true,
                    skip: true,
                    isAvatar: false,
                    time: "",
                },
                {
                    id: this.messagges.length + 1,
                    question: "Attendi qualche secondo...",
                    answer: "",
                    confirmBtn: false,
                    await: true,
                    skip: true,
                    isAvatar: false,
                    time: "",
                }
            );
        },
        clickProvinceCheck() {
            if (!this.selectedCity) {
                alert("Seleziona una città");
            } else {
                this.messagges[this.step - 1].answer = this.selectedCity;

                this.eventTracking.trackEvent(
                    this.nameLanding + "_city",
                    "click",
                    this.selectedCity,
                    true,
                    false,
                    null,
                    null
                );

                if (this.$gtm.enabled()) {
                    window.dataLayer.push({
                        event: this.nameLanding + "_city",
                        event_value: this.selectedCity,
                    });
                }

                this.nextStep();
            }
        },
        scrollToBottom() {
            this.$refs.bottomEl?.scrollIntoView({ behavior: "smooth" });
        },
        validateForm() {
            // Validate Data & Show Errors
            const checks = new Checks();
            let good_lead = true;
            let success = false;
            this.errors = [];

            if (!this.formData.first_name) {
                this.errors.push("Inserisci un nome esatto");
            }

            if (!this.formData.last_name) {
                this.errors.push("Inserisci un cognome esatto");
            }

            if (!this.formData.email) {
                this.errors.push("Inserisci un email valido");
            } else if (!checks.checkEmail(this.formData.email)) {
                this.errors.push("Inserisci un email valido");
            }

            if (!this.formData.mobile_phone) {
                this.errors.push("Inserisci un numero esatto");
            } else if (!checks.checkPhone(this.formData.mobile_phone)) {
                this.errors.push("Inserisci un numero esatto");
            } else {
                sessionStorage.setItem("$phoneNum", this.formData.mobile_phone);
            }

            if (!checks.dateIsValid(moment(this.date).format("DD/MM/YYYY"))) {
                this.errors.push("Inserisci una data di nascita valida");
            }

            if (!this.errors.length) {
                this.formData.birth_date = moment(this.date).format(
                    "DD/MM/YYYY"
                );
                sessionStorage.setItem("phone_num", this.formData.mobile_phone);

                this.eventTracking.trackEvent(
                    this.nameLanding + "_form",
                    "click",
                    "",
                    true,
                    false,
                    null,
                    null
                );

                if (this.$gtm.enabled()) {
                    window.dataLayer.push({
                        event: this.nameLanding + "_form",
                    });
                }

                this.nextStep();
            }
        },
        sendLead(force_save: boolean = false) {
            const utils: Utils = new Utils();
            const prefilledParams = utils.prefillParams();

            let self = this;
            let body = {
                first_name: this.formData.first_name,
                last_name: this.formData.last_name,
                mobile_phone: this.formData.mobile_phone,
                email: this.formData.email,
                city: this.formData.citta,
                cap: null,
                birth_date: moment(
                    this.formData.birth_date,
                    "DD/MM/YYYY"
                ).format("YYYY-MM-DD"),
                utm_source: prefilledParams ? prefilledParams.utm_source : "",
                utm_medium: prefilledParams ? prefilledParams.utm_medium : "",
                utm_campaign: prefilledParams
                    ? prefilledParams.utm_campaign
                    : "",
                ad_id: prefilledParams ? prefilledParams.ad_id : "",
                adset_id: prefilledParams ? prefilledParams.adset_id : "",
                ad_name: prefilledParams ? prefilledParams.ad_name : "",
                adset_name: prefilledParams ? prefilledParams.adset_name : "",
                placement: prefilledParams ? prefilledParams.placement : "",
                user_type: this.user_type,
                already_done_courses: this.esperienza_scolastica,
                internet_access: this.internetAccess,
                uuid_user: utils.getUUID(),
                regione_source: null,
                name_landing: this.nameLanding,
                uuid_landing: this.uuid_landing,
                uuid_customer: this.uuid_customer,
                fbc: utils.getCookie("_fbc"),
                fbp: utils.getCookie("_fbp"),
            };
            if (
                force_save &&
                this.invalidPhone === sessionStorage.getItem("$phoneNum")
            ) {
                body["mobile_phone"] = this.invalidPhone;
                body["force_save"] = true;
            }

            axios
                .post(
                    "https://trk.everset.dev/leads/insertuser/" +
                        body.uuid_customer +
                        "/" +
                        body.uuid_landing,
                    body,
                    {
                        headers: {
                            token: "jpa%rg(Qj5%#!p+w5NkeL2ACNH*TtD",
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                )
                .then(async (response) => {
                    this.pushAnswersValid();
                    self.nextStep();
                    //fbq("track", "CompleteRegistration");

                    this.eventTracking.trackEvent(
                        "CompleteRegistration",
                        "register",
                        "",
                        true,
                        false,
                        null,
                        null
                    );

                    if (this.$gtm.enabled()) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: "istitutocortivo_good_lead_chat_test",
                        });
                    }

                    setTimeout(() => {
                        self.$router.push("/thankyou");
                    }, 5000);
                })
                .catch((error) => {
                    if (
                        error &&
                        error.response.data.message ===
                            "Phone Number validation failed"
                    ) {
                        self.pushInvalidPhone();
                        self.nextStep();
                    } else if (
                        error &&
                        error.response.data.message === "User Exist"
                    ) {
                        self.$router.push("/thankyou");
                    } else {
                        alert("Error");
                    }
                });
        },
    },
    mounted() {
        setTimeout(() => {
            this.messagges[this.step - 1].await = false;
            this.nextStep();
        }, 2000);
    },
};
</script>

<style>
main {
    background: linear-gradient(30deg, #7bc9f3e2, #43a5dada),
        url(../assets/images/main-img.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow-y: scroll;
}
</style>
