const questions = [
    {
        id: 1,
        question:
            "<p style='margin-bottom:10px'>Diventa ASSISTENTE ALL'INFANZIA con il <span>corso</span>di Istituto Cortivo e <strong>svolgi il Tirocinio vicino casa tua.</strong></p><ul style='list-style:none'><li>👩‍💻 Segui le lezioni online quando vuoi in base ai tuoi impegni</li><li>💼 Esperienza pratica in strutture di tutta Italia con 200 ore di Tirocinio Garantito</li><li>👩‍🎓 Tutor personale per aiutarti nel tuo percorso</li></ul><br><br><p>💶 Scopri se sei idonea per la<strong> BORSA DI STUDIO da €500</strong>!</p>",
        answers: [],
        confirmBtn: false,
        await: true,
        isAvatar: false,
        time: "",
    },
    {
        id: 2,
        question:
            "Qual è la tua esperienza scolastica? <br><div class='' style='background-color:#fff;text-align:center;border-radius:12px'><div style='text-align:start;padding:10px'><input type='radio' id='licenza_media_inferiore' name='esperienza_scolastica' value='licenza media inferiore'><label style='color:#000' for='licenza_media_inferiore'> licenza media inferiore</label><br><input type='radio' id='licenza_media_superiore/corso professionale' name='esperienza_scolastica' value='licenza media superiore/corso professionale'><label style='color:#000' for='licenza_media_superiore/corso professionale'> licenza media superiore/corso professionale</label><br><input type='radio' id='laurea_triennale' name='esperienza_scolastica' value='laurea triennale'><label style='color:#000' for='laurea_triennale'> laurea triennale</label><br><input type='radio' id='laurea_magistrale/master' name='esperienza_scolastica' value='laurea magistrale/master'><label style='color:#000' for='laurea_magistrale/master'> laurea magistrale/master</label><br><br></div></div>",
        answer: "",
        confirmBtn: true,
        await: true,
        questionName: "esperienza_scolastica",
        isAvatar: true,
        time: "",
    },
    {
        id: 3,
        question:
            "Lezioni ed esami si svolgono online, hai accesso a internet, anche occasionalmente, tramite qualsiasi dispositivo (es: cellulare, tablet, pc...)?",
        answer: "",
        confirmBtn: false,
        isMultiplyBtns: true,
        multiplyBtns: [
            {
                id: 1,
                name: "Si",
                value: "Si",
            },
            {
                id: 2,
                name: "No",
                value: "No",
            },
        ],
        await: true,
        questionName: "internetAccess",
        isAvatar: true,
        time: "",
    },
    {
        id: 4,
        question:
            "Hai bisogno che il corso si adatti alle tue esigenze di: (puoi selezionare più opzioni) <br> <div style='max-width:200px;text-align:start;padding:10px'><input type='checkbox' id='Mamma' name='user_type' value='Mamma'> <label style='color:#000' for='Mamma'>Mamma</label><br><input type='checkbox' id='Studentessa' name='user_type' value='Studentessa'> <label style='color:#000' for='Studentessa'>Studentessa</label><br><input type='checkbox' id='Lavoratrice' name='user_type' value='Lavoratrice'> <label style='color:#000' for='Lavoratrice'>Lavoratrice</label><br><input type='checkbox' id='Nessuna' name='user_type' value='Nessuna di queste'> <label style='color:#000' for='Nessuna'>Nessuna di queste</label><br><br></div> ",
        answer: "",
        confirmBtn: true,
        await: true,
        questionName: "user_type",
        isAvatar: true,
        time: "",
    },
    {
        id: 5,
        question: "Bene! Costruiamo il corso completamente su misura per te!",
        answer: "",
        confirmBtn: false,
        await: true,
        skip: true,
        isAvatar: false,
        time: "",
    },
    {
        id: 6,
        question:
            "<p>La parte più bella è il Tirocinio GARANTITO, per avere subito esperienza pratica del tuo futuro lavoro e <strong>farti conoscere dalle strutture della tua zona</strong>.</p>",
        answer: "",
        confirmBtn: false,
        await: true,
        skip: true,
        isAvatar: false,
        time: "",
    },
    {
        id: 7,
        question:
            "<p>Ci occupiamo noi dell'inserimento nelle nostre 12.500 strutture convenzionate in tutta Italia.<br><u>Tu in che città vivi?</u></p>",
        answer: "",
        confirmBtn: false,
        await: true,
        skip: false,
        isInput: true,
        questionName: "citta",
        isAvatar: true,
        time: "",
    },
    {
        id: 8,
        question:
            "Ottimo! Mi risultano alcune strutture proprio vicino casa tua in cui potrai svolgere il tirocinio!",
        answer: "",
        confirmBtn: false,
        await: true,
        skip: true,
        isAvatar: false,
        time: "",
    },
    {
        id: 9,
        question:
            "<p>Benissimo, hai buone possibilità di accedere alla borsa di studio! Ma ne sono rimaste <strong>solo 3 disponibili per la tua provincia. </strong><br>Completa la richiesta <u>entro i prossimi 5 minuti</u> per bloccare una delle borse di studio (senza impegno) e ricevere informazioni:</p>",
        answer: "",
        confirmBtn: false,
        await: true,
        skip: true,
        isAvatar: false,
        time: "",
    },
    {
        id: 10,
        question: "",
        answer: "",
        confirmBtn: false,
        await: true,
        isForm: true,
        isAvatar: true,
        time: "",
        questionName: "form",
    },
    {
        id: 11,
        question:
            "Perfetto <span id='nomeHtml'></span>, per proseguire, acconsenti che i tuoi Dati Personali siano comunicati a terzi esclusivamente per le finalità di marketing come da <a href='https://everset.it/privacy-policy/it/privacy-policy.pdf' target='_blank'>Privacy Policy</a>",
        answer: "",
        confirmBtn: false,
        isMultiplyBtns: true,
        multiplyBtns: [
            {
                id: 1,
                name: "ACCETTO ✅",
                value: "ACCETTO",
            },
            {
                id: 2,
                name: "RIFIUTO 🚫",
                value: "RIFIUTO",
            },
        ],
        await: true,
        questionName: "privacy",
        isAvatar: true,
        time: "",
    },
];

export default questions;
