<template>
    <header>
        <div class="header sticky" id="myHeader">
            <div class="logo">
                <img src="../assets/images/cortivo-tu-nel-sociale.png" class="mr-3 max-w-[100px] w-full" alt="Cortivo" />
            </div>
        </div>
    </header>
</template>

<script>
export default {

}
</script>

<style></style>