<script lang="ts">
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import { RouterView } from "vue-router";
import Utils from "./shared/utils";
import EventsTracking from "@/shared/eventsTracking";

export default {
    components: { Header, Footer },
    data() {
        const eventTracking: EventsTracking = new EventsTracking(
            import.meta.env.VITE_APP_NAME_LANDING,
            import.meta.env.VITE_APP_UUID_CUSTOMER,
            import.meta.env.VITE_APP_UUID_LANDING
        );

        return {
            eventTracking,
        };
    },
    created() {
        const utils = new Utils();
        utils.setUUID();
    },
    mounted() {
        const utils = new Utils();

        fbq("init", "397029719183459", {
            external_id: utils.getUUID(),
            country: "it",
        });
        fbq("init", "1404363593523710", {
            external_id: utils.getUUID(),
            country: "it",
        });
        fbq("track", "PageView", {}, { eventID: utils.getUUID() });

        this.eventTracking.trackEvent(
            "PageView",
            "track",
            "",
            true,
            true,
            null,
            null
        );
    },
    methods: {},
};
</script>

<template>
    <Header />
    <RouterView />
    <Footer />
</template>

<style scoped>
.logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
}

.logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
    filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
